import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../components/elements/Navbar'
import { Subtitle } from './datenschutz'
import components from '../content/common'
import Footer from '../components/elements/Footer'

import imageDoppelStock from '@images/presse/presse-huettn-1-preview.jpg'
import imageFestlBadReichenhall from '@images/presse/presse-fest-bad-reichenhall-1-preview.jpg'
import imageFestlOberaudorf from '@images/presse/presse-fest-oberaudorf-1-preview.jpg'
import imageFestlRuhpolding from '@images/presse/presse-fest-ruhpolding-1-preview.jpg'
import imageFestlRuhpolding2024 from '@images/presse/presse-fest-ruhpolding-2024-preview.jpg'
import imageFestlOberammergau from '@images/presse/presse-fest-oberammergau-1-preview.jpg'
import imageBpong from '@images/presse/presse-bpong-preview.jpg'
import imageBrassSonntag from '@images/presse/brass-sonntag-preview.jpg'
import imageEinhell from '@images/presse/brauhaus-vanhans-preview.jpg'
import imageDartsWM24 from '@images/presse/brauhaus-darts-wm-24-preview.jpg'
import imageSpendenscheckuebergabe from '@images/presse/spendenscheckuebergabe.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    ${tw`text-18px`}
    ${tw`font-bold`}

    &:before {
      content: '>';
    }

    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const PresseTeaser = tw.div`text-white`
const PresseTeaserSmall = tw.div`text-15px mb-2`

const PressePage = () => {

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full mb-4">
                Pressemeldungen
              </Subtitle>
              <div tw="grid gap-4 grid-cols-1 md:grid-cols-2">
                <PresseTeaser>
                  <a 
                      href="./festl-ruhpolding-2024/"
                    >
                      <img src={imageFestlRuhpolding2024} alt="Eindrücke der legendären letztjährigen Alpin FM Festl Tour mit ERDINGER Brauhaus" />
                  </a>
                  <PresseTeaserSmall>
                    Erding, 18.07.2024<br />
                    <strong tw="text-18px mt-2 block">Die Alpin FM Festl Tour</strong>
                    mit ERDINGER Brauhaus macht Halt im charmanten Ruhpolding
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./festl-ruhpolding-2024/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>

                <PresseTeaser>
                  <a 
                      href="./festl-oberammergau/"
                    >
                      <img src={imageFestlOberammergau} alt="Eindrücke der legendären letztjährigen Alpin FM Festl Tour mit ERDINGER Brauhaus" />
                  </a>
                  <PresseTeaserSmall>
                    Erding, 11.07.2024<br />
                    <strong tw="text-18px mt-2 block">Die Alpin FM Festl Tour</strong>
                    mit ERDINGER Brauhaus macht Halt in Oberammergau
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./festl-oberammergau/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>
                <PresseTeaser>
                  <a 
                      href="./festl-bad-reichenhall/"
                    >
                      <img src={imageFestlBadReichenhall} alt="Eindrücke der legendären letztjährigen Alpin FM Festl Tour mit ERDINGER Brauhaus" />
                  </a>
                  <PresseTeaserSmall>
                    Erding, Juli 2024<br />
                    <strong tw="text-18px mt-2 block">Die Alpin FM Festl Tour</strong>
                    mit ERDINGER Brauhaus macht Halt in Bad Reichenhall
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./festl-bad-reichenhall/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>
                <PresseTeaser>
                  <a 
                      href="./spendenscheckuebergabe/"
                    >
                      <img src={imageSpendenscheckuebergabe} alt="Gelebte Bier- und Heimatliebe: ERDINGER Brauhaus setzt Spendentradition fort" />
                  </a>
                  <PresseTeaserSmall>
                    Erding, Juni 2024<br />
                    <strong tw="text-18px mt-2 block">Gelebte Bier- und Heimatliebe</strong>
                    ERDINGER Brauhaus setzt Spendentradition fort
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./spendenscheckuebergabe/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>
                <PresseTeaser>
                  <a 
                      href="./darts-wm-24/"
                    >
                      <img src={imageDartsWM24} alt="Game on! ERDINGER Brauhaus wird Partner der PDC Darts-WM 2024 auf SPORT1" />
                  </a>
                  <PresseTeaserSmall>
                    Erding, Dezember 2023<br />
                    <strong tw="text-18px mt-2 block">PDC Darts-WM 2024 auf SPORT1</strong>
                    ERDINGER Brauhaus wird Partner
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./darts-wm-24/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>
                <PresseTeaser>
                  <a 
                      href="./einhell/"
                    >
                      <img src={imageEinhell} alt="Dr. Markus Thannhuber überreicht die ERDINGER Brauhaus-Edition des VanHans Nagelstocks an Dr. Stefan Kreisz" />
                  </a>
                  <PresseTeaserSmall>
                    Erding, Dezember 2023<br />
                    <strong tw="text-18px mt-2 block">Let’s nail it!</strong>
                    ERDINGER Brauhaus x Einhell
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./einhell/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>
                <PresseTeaser>
                  <a 
                      href="./brass-sonntag/"
                    >
                      <img src={imageBrassSonntag} alt="Das große Finale der Alpin FM Festl Tour im ERDINGER Weißbräu-Festzelt" />
                  </a>
                  <PresseTeaserSmall>
                    Erding, August 2023<br />
                    <strong tw="text-18px mt-2 block">Das gab es noch nie!</strong>
                    Brass Sonntag auf dem Herbstfest in Erding
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./brass-sonntag/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>
                <PresseTeaser>
                  <a 
                      href="./bpong/"
                    >
                      <img src={imageBpong} alt="ERDINGER Brauhaus und die BPong-Bundesliga stoßen auf die neue Partnerschaft an." />
                  </a>
                  <PresseTeaserSmall>
                    Erding, Juli 2023<br />
                    <strong tw="text-18px mt-2 block">It's a hit</strong>
                    ERDINGER Brauhaus wird Partner der BPong-Bundesliga
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./bpong/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>
                <PresseTeaser>
                  <a 
                      href="./festl-ruhpolding/"
                    >
                      <img src={imageFestlRuhpolding} alt="Eindrücke der legendären letztjährigen Alpin FM Festl Tour mit ERDINGER Brauhaus" />
                  </a>
                  <PresseTeaserSmall>
                    Erding, Juli 2023<br />
                    <strong tw="text-18px mt-2 block">Die Alpin FM Festl Tour</strong>
                    mit ERDINGER Brauhaus  zu Gast in Ruhpolding
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./festl-ruhpolding/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>
                <PresseTeaser>
                  <a 
                      href="./festl-oberaudorf/"
                    >
                      <img src={imageFestlOberaudorf} alt="Eindrücke der legendären letztjährigen Alpin FM Festl Tour mit ERDINGER Brauhaus" />
                  </a>
                  <PresseTeaserSmall>
                    Erding, Juli 2023<br />
                    <strong tw="text-18px mt-2 block">Die Alpin FM Festl Tour</strong>
                    mit ERDINGER Brauhaus macht Station in Oberaudorf
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./festl-oberaudorf/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>
                <PresseTeaser>
                  <a 
                      href="./doppelstock/"
                    >
                      <img src={imageDoppelStock} alt="Bei der Pressekonferenz zur Vorstellung der ERDINGER Hütt'n (v.l.): Josef Westermeier (Geschäftsführer Marketing und Vertrieb ERDINGER Weißbräu), die neuen Festwirte Manfred Kolbeck und David Ritter sowie Wolfgang Kuffner (Gesamtleitung Marketing ERDINGER Weißbräu) - Bildquelle: Hans Moritz" />
                       
                  </a>
                  <PresseTeaserSmall>
                    Erding, Juni 2023<br />
                    <strong tw="text-18px mt-2 block">Erste ERDINGER Doppelstock-Hütt’n</strong>
                    mit den neuen Festwirten David Ritter und Manfred Kolbeck
                  </PresseTeaserSmall>
                  <a
                      className="text-green"
                      href="./doppelstock/"
                    >
                    Zur Pressemitteilung
                  </a>
                </PresseTeaser>
              </div>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
